<template>
    <div class="dashboard-gifts">
        <DashCardRouter title="Premios" />
        <div class="dashboard-gifts__tabs">
            <button
                class="dashboard-gifts__tabs-item"
                :class="
                    activeTab === false
                        ? 'font-semibold text-site-primary'
                        : 'hover:brightness-130 font-medium text-[#6B6870]'
                "
                @click="activeTab = false"
            >
                <img :src="$assets.primary.star" :alt="$lang.pages.dashboardPremios.available_awards" />
                {{ $lang.pages.dashboardPremios.available_awards }}
            </button>
            <button
                class="dashboard-gifts__tabs-item"
                :class="
                    activeTab === true
                        ? 'font-semibold text-site-primary'
                        : 'hover:brightness-130 font-medium text-[#6B6870]'
                "
                @click="activeTab = true"
            >
                <img :src="$assets.primary.books" alt="Historico de premios ganados" />
                {{ $lang.pages.dashboardPremios.historical_awards }}
            </button>
        </div>
        <transition-group name="slide-from-left">
            <div v-if="activeTab === false">
                <section v-if="giftsData.results.length" class="dashboard-gifts-available">
                    <article
                        v-for="(gift, index) in giftsData.results"
                        :key="index"
                        class="dashboard-gifts-available__wrapper"
                    >
                        <div class="dashboard-gifts-available__item">
                            <div class="dashboard-gifts-available__image-and-title">
                                <img :src="$assets.illustration.dashboardGift" :alt="gift.name" />
                                <p>{{ gift.name }}</p>
                                <div class="dashboard-gifts-available__amount">
                                    {{ gift.amount }}
                                    <span>{{ $lang.pages.dashboardPremios.points }}</span>
                                </div>
                            </div>

                            <button
                                class="dashboard-gifts-available__link"
                                type="button"
                                @click="redeem(gift.id)"
                            >
                                <p>{{ $lang.pages.dashboardPremios.redeem }}</p>
                                <div class="dashboard-gifts-available__link-icon">
                                    <i class="far fa-long-arrow-right"></i>
                                </div>
                            </button>
                        </div>
                    </article>
                </section>

                <MiscNoResults
                    v-else
                    mode="no-prizes-available"
                    :title="$lang.pages.dashboardPremios.no_prizes_available"
                    :subtitle="$lang.pages.dashboardPremios.come_back_later"
                />

                <ButtonLoadMore v-if="fetchButton.show" :loading="fetchButton.loading" :handler="fetch" />
            </div>

            <div v-if="activeTab">
                <!-- <ListGiftHistory v-if="history.length" :gift-list="history" /> -->
                <div v-if="historyData.length" class="dashboard-gifts-historial">
                    <article
                        v-for="(item, index) in historyData"
                        :key="index"
                        class="dashboard-gifts-historial__wrapper"
                    >
                        <div class="dashboard-gifts-historial__image-title">
                            <img :src="$assets.illustration.dashboardGift" alt="gift" />
                            <div class="dashboard-gifts-historial__title">
                                <h3>{{ item.title }}</h3>
                                <p class="">
                                    {{ item.points }}
                                    <span>{{ $lang.pages.dashboardPremios.coins }}</span>
                                </p>
                            </div>
                        </div>
                        <div v-if="item.created_at" class="dashboard-gifts-historial__date-info">
                            <p>{{ formatDate(item.created_at) }}</p>
                            <div class="dashboard-gifts-historial__info">
                                <div><i class="far fa-check"></i></div>
                                {{ $lang.pages.dashboardPremios.award_redeemed }}
                            </div>
                        </div>
                    </article>
                </div>
                <MiscNoResults
                    v-else
                    mode="no-prizes"
                    :title="$lang.pages.dashboardPremios.no_prizes_achieved"
                    :subtitle="$lang.pages.dashboardPremios.keep_farming"
                />
            </div>
        </transition-group>
    </div>
</template>

<script lang="ts" setup>
import { format, parseISO } from 'date-fns'
import { es as locale } from 'date-fns/locale'
import { useAuthStore } from '~/store/auth'
import { useRootStore } from '~/store/root'
import type { Models } from '~/types/models'

import type { Api } from '~~/global'

const { UserData, SessionToken } = useAuthStore()
const { $lang } = useNuxtApp()

const params = reactive({
    per_page: 10,
    page: 1,
})
const { buildHeaders, baseURL, endpoints } = useApiConfig()
const getGiftsHistory = () =>
    $fetch<Api.Responses.General.RewardsHistory>(
        endpoints.general.giftsHistory.path.replace('_ID_', String(UserData?.id || '')),
        {
            headers: buildHeaders(SessionToken),
            baseURL,
            method: 'GET',
        },
    ).catch((e) => e.data)
const getGifts = () =>
    $fetch<Api.Responses.General.IndividualModel<Models.Gift>>(
        endpoints.general.gifts.path.replace('_ID_', String(UserData?.id || '')),
        {
            headers: buildHeaders(SessionToken),
            baseURL,
            method: 'GET',
            params,
        },
    ).catch((e) => e.data)

const { data: responseData, error } = await useAsyncData('dashboard-user-activities', async () => {
    const history = await getGiftsHistory()

    const gifts = await getGifts()

    return { history, gifts }
})

if (
    error.value ||
    !responseData.value?.gifts ||
    !responseData.value?.history ||
    responseData.value.history.error ||
    responseData.value.gifts.error
) {
    throw createError({
        statusCode: 404,
        message: error.value?.message || 'Error data',
    })
}

params.page = 2

const Route = useRoute()
const {
    public: { origin },
} = useRuntimeConfig()

const title = $lang.pages.dashboardPremios.title_seo
const description = $lang.pages.dashboardPremios.description_seo

useSeoMeta({
    title: title,
    ogUrl: origin + Route.path,
    ogTitle: title,
    description: description,
    ogDescription: description,
    ogImageAlt: title,
    twitterCard: 'summary_large_image',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: origin + Route.path,
        },
    ],
})

const historyData = ref(responseData.value.history.data.gifts)

const giftsData = ref(responseData.value.gifts.data)

const activeTab = ref(false)

const fetchButton = reactive({
    loading: false,
    show:
        giftsData.value.meta.total > 0 && giftsData.value.meta.current_page < giftsData.value.meta.last_page,
})

const formatDate = (date: string) => {
    return format(parseISO(date), 'dd/MM/yyyy', { locale })
}
const fetch = async () => {
    fetchButton.loading = true

    const { feedback, data } = await getGifts()

    if (feedback === 'data_success') {
        if (data.meta.current_page === 1) {
            giftsData.value = data
        } else {
            giftsData.value = {
                ...data,
                results: [...giftsData.value.results, ...data.results],
            }
        }
        if (data.meta.current_page < data.meta.last_page) {
            params.page = data.meta.current_page + 1
        } else {
            fetchButton.show = false
        }
    }

    fetchButton.loading = false
}

const RootStore = useRootStore()

const redeem = async (id: any) => {
    const result = await $fetch<Api.Responses.Actions.SuccessRedeem | Api.Responses.Actions.FailedRedeem>(
        endpoints.general.redeemGifts.path,
        {
            headers: buildHeaders(SessionToken),
            baseURL,
            method: 'POST',
            body: {
                gift_id: id,
            },
        },
    ).catch((e) => e.data)

    if (result.feedback === 'resource_created') {
        RootStore.setSiteNotification({
            text: $lang.pages.dashboardPremios.redeem_success_awaiting,
            duration: 4000,
            dismissButtonText: $lang.pages.dashboardPremios.close,
            type: 'success',
        })
    }
    if (result.feedback === 'params_validation_failed') {
        RootStore.setSiteNotification({
            text: result.data.gift_id?.length
                ? result.data.gift_id[0]
                : result.data.coins_available?.length
                  ? result.data.coins_available[0]
                  : $lang.pages.dashboardPremios.error_on_redeem,
            duration: 4000,
            dismissButtonText: $lang.pages.dashboardPremios.close,
            type: 'error',
        })
    }
}
</script>

<style lang="postcss" scoped>
.dashboard-gifts {
    @apply p-2;
    &__tabs {
        @apply mx-3 mb-3 mt-5 flex flex-wrap justify-evenly gap-y-1 sm:rounded-xl sm:shadow-rounded lg:p-2;
        &-item {
            @apply flex w-full items-center justify-center gap-3 rounded-xl p-2 text-base shadow-rounded transition duration-200 sm:w-max sm:gap-1.5 sm:rounded-none sm:text-lg sm:shadow-none lg:hover:brightness-110;
            img {
                @apply h-4 w-4;
            }
        }
    }
    &-available {
        @apply grid grid-cols-1 gap-2 p-3 lg:grid-cols-2 lg:gap-5;
        &__wrapper {
            @apply rounded-xl bg-[#F5F4F6];
        }
        &__item {
            @apply flex h-40 flex-col justify-between p-4 lg:h-[12.8rem];
        }
        &__image-and-title {
            @apply flex flex-col gap-x-2 gap-y-2 text-sm font-semibold text-[#404040] lg:text-base;
            p {
                @apply line-clamp-1 lg:line-clamp-2;
            }
            img {
                @apply h-8 w-8 lg:h-11 lg:w-11;
            }
        }
        &__amount {
            @apply text-xl font-semibold leading-4 text-site-primary;
            span {
                @apply text-xs;
            }
        }
        &__link {
            @apply flex cursor-pointer gap-1.5 self-end rounded-full border border-site-primary bg-site-primary px-2 py-1 text-sm font-medium text-white transition duration-200;

            span:last-child {
                @apply lowercase;
            }
            &-icon {
                @apply pt-0.5 text-center;
            }
        }
    }
    &-historial {
        @apply space-y-3 p-3;
        &__wrapper {
            @apply w-full justify-between space-y-2 rounded-xl bg-[#F5F4F6] p-3 lg:flex;
        }
        &__image-title {
            @apply flex items-center gap-2;
            img {
                @apply h-12 w-12 lg:h-10 lg:w-10;
            }
        }
        &__title {
            h3 {
                @apply text-sm font-semibold text-[#404040] lg:text-base;
            }
            p {
                @apply text-base font-semibold text-site-primary lg:text-lg;
                span {
                    @apply text-xs font-semibold lg:text-sm;
                }
            }
        }
        &__date-info {
            @apply flex flex-row-reverse items-center justify-start gap-2 text-xs lg:flex-col lg:justify-between lg:text-sm;
            p:first-child {
                @apply w-fit text-right text-xs font-medium text-[#94949B] lg:w-full;
            }
        }
        &__info {
            @apply flex gap-1 text-right font-medium text-site-primary;
        }
    }
}
</style>
